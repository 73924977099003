@font-face {
  font-family: 'Montserrat';
  src: url('../src/assets/fonts/Montserrat-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../src/assets/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../src/assets/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../src/assets/fonts/Montserrat-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../src/assets/fonts/Montserrat-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0 !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Ancho del scrollbar */
  height: 10px;
}

/* Fondo del track (espacio detrás del scrollbar) */
::-webkit-scrollbar-track {
  background-color: transparent; /* Lo hacemos transparente */
  margin-top: 8px; /* Deja espacio arriba */
  margin-bottom: 5px; /* Deja espacio abajo */
}

/* Estilo del "thumb" (barra de desplazamiento) */
::-webkit-scrollbar-thumb {
  background-color: #A7B1BF;
  border-radius: 20px;
  z-index: 101;
}

/* Cuando el cursor pasa sobre el video, el scrollbar se vuelve más transparente */
.video-container:hover ~ body::-webkit-scrollbar-thumb {
  background-color: rgba(167, 177, 191, 0.3); /* Más transparente */
}
